import React, { Component } from 'react';
import { connect } from 'react-redux';

import Aux from '../../hoc/Aux/Aux';
import Burger from '../../components/Burger/Burger';
import BuildControls from '../../components/Burger/BuildControls/BuildControls';
import Modal from '../../components/UI/Modal/Modal';
import OrderSummary from '../../components/Burger/OrderSummary/OrderSummary';
import Spinner from '../../components/UI/Spinner/Spinner';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import axios from '../../axios-orders';
import * as actions from '../../store/actions/index';


export class BurgerBuilder extends Component{
    state = {
        cartVisibility: false
    }

    componentDidMount (){
        //console.log(this.props);
        this.props.onInitIngredients();
    }

    updatePurchaseState (ingredients) {
        const sum = Object.keys(ingredients)
                    .map(igKey => {
                        return ingredients[igKey];
                    })
                    .reduce((sum, el) => {
                        return sum + el;
                    }, 0);
        return sum > 0;
    }

    cartHandler = () => {
        if (this.props.isAuthenticated){
            this.setState({cartVisibility: true});
        }
        else{
            this.props.onSetAuthRedirectPath('/checkout');
            this.props.history.push('/auth');
        }
    }

    closeCartHandler = () => {
        this.setState({cartVisibility: false})
    }

    confirmPurchaseHandler = () => {

        this.props.history.push('/checkout');
    }

    render(){
        const disableInfo = {
            ...this.props.ings

        };

        for (let key in disableInfo ){
            disableInfo[key] = disableInfo[key] <= 0
        }
        let orderSummary = null;

        let burger = this.props.error ? <p>The ingrediends cannot be loaded...</p> : <Spinner />;

        if(this.props.ings){
            burger = (<Aux>
                <Burger ingredients={this.props.ings} />
                <BuildControls 
                    price={this.props.price}
                    ingredientAdded={this.props.onIngredientAdded} 
                    ingredientRemoved={this.props.onIngredientRemoved}
                    disabled={disableInfo} 
                    purchaseable={this.updatePurchaseState(this.props.ings)}
                    isAuth={this.props.isAuthenticated}
                    ordered={this.cartHandler}/></Aux>
                );
            orderSummary = <OrderSummary   purchaseCancelled={this.closeCartHandler}
            purchaseConfirmed={this.confirmPurchaseHandler}
            ingredients={this.props.ings}
            amountDue={this.props.price}/>;
        }  

        return (
            <Aux>
                <Modal show={this.state.cartVisibility} exitModal={this.closeCartHandler}>
                    {orderSummary}
                </Modal>
                {burger}
            </Aux>
        );
    }
}

const mapStateToProps = state => {
    return {
        ings: state.burgerBuilder.ingredients,
        price: state.burgerBuilder.totalPrice,
        error: state.order.error,
        isAuthenticated: state.auth.token !== null
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onIngredientAdded: (ingName) => dispatch(actions.addIngredient(ingName)),
        onIngredientRemoved: (ingName) => dispatch(actions.removeIngredient(ingName)),
        onInitIngredients: () => dispatch(actions.initIngredients()),
        onInitPurchase: () => dispatch(actions.purchaseInit()),
        onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler( BurgerBuilder, axios ));