import React from 'react';
import classes from './Burger.module.css';
import BurgerIngedient from './BurgerIngredient/BurgerIngredient';

const burger = (props) => {
    let transformedIngredients = Object.keys(props.ingredients)
        .map(igKey => {
            return [...Array(props.ingredients[igKey])].map((_, i) => {
                return <BurgerIngedient key={igKey + i} burgertopping={igKey} />;
            });
        })
        .reduce((arr, el) => {
            return arr.concat(el);
        }, []);
        if (transformedIngredients.length === 0){
            transformedIngredients = <p>Please add some ingredients</p>;
        }
    return (
        <div className={classes.Burger}>
            <BurgerIngedient burgertopping="bread-top" />
            {transformedIngredients}
            <BurgerIngedient burgertopping="bread-bottom" />
        </div>
    );
}

export default burger;