import React, { Component } from 'react';

import Aux from '../../../hoc/Aux/Aux';
import Button from '../../UI/Button/Button';

class OrderSummary extends Component {
    /*
        enabled for debugging.
        this could be a functional component; 
        doesnt have to be a class.
    */
    componentDidUpdate(){
        //console.log('[Order Summary] did update');
    }

    render(){

        const ingredientSummary = Object.keys(this.props.ingredients)
        .map(igKey => {
            return (<li key={igKey}>
                        <span style={{textTransform: 'capitalize'}}>{igKey}</span>: 
                        {this.props.ingredients[igKey]}
                    </li>);
        });

        return(
            <Aux>
                <h3>
                    Your Order:
                </h3>
                <ul>
                    {ingredientSummary}
                </ul>
                <p>
                    <strong>
                        Amount Due: &#36;{this.props.amountDue.toFixed(2)} 
                    </strong>
                </p>
                <p>
                    Ready to checkout?
                </p>
                <Button btnType="Danger" clicked={this.props.purchaseCancelled}>
                    Cancel
                </Button>
                <Button btnType="Success" clicked={this.props.purchaseConfirmed}>
                    Checkout
                </Button>
            </Aux>
        );
    }
}

export default OrderSummary;